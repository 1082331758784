import { injectIntl } from "gatsby-plugin-intl"
import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"

class Disinfezione extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMobileMenu: false }
  }

  toggleMenu() {
    this.setState(prevState => {
      return { showMobileMenu: !prevState.showMobileMenu }
    })
  }

  render() {
    const { showMobileMenu } = this.state

    return (
      <Layout
        showMobileMenu={showMobileMenu}
        toggleMenu={this.toggleMenu.bind(this)}
      >
        <SEO />
        <h2>LA DISINFEZIONE SPECIALIZZATA:</h2>
        <p>
          La disinfezione specializzata è la risposta che Lunasol, in collaborazione con Infinity Biotech, ha scelto di dare alla sempre maggior richiesta di interventi di disinfezione ad alto livello. Studiata inizialmente soprattutto per ambienti ospedalieri - sale operatorie, successivamente adattata anche a scuole, uffici, capannoni, questa disinfezione si avvale di tecnologie all’avanguardia che garantiscono una prestazione con il 99,9999% di efficacia.
        </p>

        <h2>TECNOLOGIA iCUBE:</h2>

        <p>iCUBE, in tutte le sue versioni, è di fatto un atomizzatore di alto livello “no touch” e viene prodotto nello stabilimento di IB SpA. Atomizza perossido di Idrogeno stabilizzato naturalmente come liquido per la disinfezione da agenti patogeni di ambienti, superfici, condutture d’acqua e condotte idrauliche e aerauliche, senza l’uso di alcun additivo né di materiali pesanti, come argento e oro.</p>

        <h2>PERCHE’ SCEGLIERE LUNASOL:</h2>

        <p>Noi non usiamo diffusori, nebulizzatori  o spruzzatori comuni. Questo perché il più delle volte essi rilasciano negli ambienti importanti residui chimici tossici, altamente nocivi per il nostro organismo. Noi usiamo atomizzatori di alto livello, con uso di disinfettanti 100% eco-compatibili e 100% biodegradabili. Dopo la nostra disinfezione, potrete rientrare nei vostri locali entro pochi minuti, senza dovervi preoccupare (ad esempio ..) del panino lasciato sulla scrivania del vostro ufficio (..) , dei documenti o di altro materiale sensibile necessario per la vostra attività. Non si devono risciacquare le superfici nè tanto meno aprire le finestre o arieggiare il locale. Questo perché la nostra nuova tecnologia “no touch” garantisce l’assoluta integrità degli oggetti e dell’ambiente in cui interveniamo.</p>

        <h2>OZONO VS PEROSSIDO DI IDROGENO:</h2>

        <ul>
          <li>
            L'ozono si genera attraverso una scarica elettrica che colpisce l'ossigeno e ciò   provoca una reazione altamente corrosiva.
          </li>
          <li>Se disinfettiamo utilizzando l’ozono, per avere gli stessi effetti del ns perossido, abbiamo la necessità di intervenire con tempi di erogazione molto superiori, con una conseguente forte esposizione degli ambienti all'azione corrosiva </li>
          <li>Inoltre dobbiamo attendere almeno due ore prima di poter consentire la ripopolazione degli ambienti, poiché la molecola residuale di ozono ha una forte persistenza negli ambienti e quindi oltretutto l'odore è nauseabondo.</li>
          <li>Forti rischi di ossidazione dell'impianto elettrico, rischi di danneggiamento di elementi presenti nell’ambiente circostante . </li>
          <li>L'ozono è certamente riconosciuto come disinfettante, ma dal punto di vista della sua reale efficacia con il suo utilizzo otteniamo in realtà solo una sanificazione deodorante e non una disinfezione di alto livello</li>
        </ul>

        <h2>SVOLGIMENTO DEL SERVIZIO: </h2>

        <p>
          L’effettuazione del servizio viene svolta in maniera totalmente “calibrata” sugli ambienti da disinfettare e ciò è richiesto dalle procedure che adottiamo: ogni ambiente/stanza/ufficio ha una sua natura (mobili e arredi presenti, dislocazione degli elementi, volumetrie etc) e pertanto richiede delle procedure appositamente studiate caso per caso per poter rendere la totale efficacia e la migliore economicità.
          Pertanto il nostro iter prevede quanto segue:
        </p>
        <ul>
          <li>Effettuare un preventivo sopralluogo per valutare lo stato degli ambienti; è per noi assolutamente importante stilare per ogni stanza un elenco dettagliato di vari aspetti da tenere ben presenti (numero dei mobili presenti, tipologia etc)</li>
          <li>Avere una planimetria esatta degli ambienti, per poter redigere un esatto piano di lavoro/esecuzione: ogni stanza/ufficio ha una sua esatta dimensione e, pertanto, l’esecuzione dovrà tenere conto per ogni stanza delle sue esatte dimensioni.</li>
        </ul>
      </Layout>
    )
  }
}

export default injectIntl(Disinfezione)
